











































































































































































































































































































import Vue from "vue";
import ChangePasswordDialog from "@/components/userManagement/ChangePasswordDialog.vue";
import { mapState, mapActions } from "pinia";
import { mapGetters } from "vuex";
import { useProfileStore } from "@/store/profileStore";
import { AreaOfInterestDto, IUpdateProfileDto, ProfileClient, ProfileDto, FileParameter } from "@/api/DoceoApi";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import DoceoImage from "@/components/DoceoImage.vue";
import { useConversationStore } from "@/store/conversationStore";
import { useChatHubStore } from "@/store/chatHubStore";
import { useContentStore } from "@/store/contentStore";
import Roles from "@/constants/roles";

export default Vue.extend({
  name: "Profile",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  async created() {
    this.getAreasOfInterests();
    this.getAllSpecialties();
    await this.loadSelectedProfile();
  },
  components: { ChangePasswordDialog, DoceoIcon, DoceoImage },
  data: () => ({
    isChangePasswordDialogOpen: false,
    isEditing: false,
    saving: false,
    uploadProfilePictureDialog: false,
    uploadProfilePictureFile: null as File | null,
    uploadProfilePicturePreview: null as string | null,
    areaOfInterest: null as AreaOfInterestDto | null,
    userProfile: {} as ProfileDto,
    DOCEO_ICONS,
    bioMaxLength: 60,
    Roles: Roles,
  }),
  methods: {
    ...mapActions(useProfileStore, ["updateProfile", "getAreasOfInterests", "getAllSpecialties", "uploadProfilePicture"]),
    ...mapActions(useContentStore, ["resetSummaries"]),
    removeAreaOfInterest(areaOfInterest: AreaOfInterestDto) {
      if (this.userProfile.areasOfInterest) {
        this.userProfile.areasOfInterest.splice(this.userProfile.areasOfInterest.indexOf(areaOfInterest), 1);
      }
    },
    addAreaOfInterest() {
      if (this.areaOfInterest) {
        this.userProfile.areasOfInterest?.push(this.areaOfInterest);
      }
      this.areaOfInterest = null;
    },
    showFileUpload() {
      let fileSelect = this.$refs.profile_picture_upload as HTMLElement;
      fileSelect.click();
    },
    setPreviewProfilePicture() {
      let fileSelect = this.$refs.profile_picture_upload as HTMLInputElement;
      let files = fileSelect.files;
      if (files != null && files.length > 0) {
        this.uploadProfilePictureFile = files[0];
        this.uploadProfilePictureDialog = false;
        this.uploadProfilePicturePreview = URL.createObjectURL(this.uploadProfilePictureFile);
      }
    },
    async saveEdit() {
      this.saving = true;
      let updatedProfile: IUpdateProfileDto = {
        userId: this.userId,
        location: this.userProfile.location,
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
        areasOfInterestIds: this.userProfile.areasOfInterest?.map((e) => e.id),
        isTakingPatients: this.userProfile.isTakingPatients,
        specialty: this.userProfile.specialty?.id,
        phoneNumber: this.userProfile.phoneNumber,
        phoneExtension: this.userProfile.phoneExtension,
        qualifications: this.userProfile.qualifications,
        bio: this.userProfile.bio,
      };

      await this.resetSummaries();

      await this.updateProfile(updatedProfile);
      if (this.uploadProfilePictureFile != null) {
        await this.uploadProfilePicture(this.uploadProfilePictureFile);
      }

      this.saving = false;
      this.isEditing = false;
    },
    cancelEdit() {
      this.isEditing = false;
      this.userProfile.location = this.profile?.location;
      this.userProfile.firstName = this.profile?.firstName;
      this.userProfile.lastName = this.profile?.lastName;
      this.userProfile.isTakingPatients = this.profile?.isTakingPatients;
      this.userProfile.specialty = this.profile?.specialty;
      this.userProfile.phoneNumber = this.profile?.phoneNumber;
      this.userProfile.phoneExtension = this.profile?.phoneExtension;
      this.uploadProfilePictureFile = null;
      this.uploadProfilePicturePreview = null;
      this.userProfile.areasOfInterest = this.profile?.areasOfInterest?.map(x => Object.assign({}, x));
    },
    async loadSelectedProfile() {
      if (!this.onOwnProfilePage) {
        let profileClient = new ProfileClient();
        this.userProfile = await profileClient.getProfile(this.$route.params.userId);
      } else {
        this.userProfile = Object.assign({}, this.profile);
        this.userProfile.areasOfInterest = this.profile?.areasOfInterest?.map(x => Object.assign({}, x));
      }
    },
    async sendMessage() {
      if (this.userProfile.email) {
        const conversationStore = useConversationStore();
        const chatroomName = `${this.name} & ${this.userProfile.firstName} ${this.userProfile.lastName}`;
        const chatroomId = await conversationStore.createChatroom(chatroomName);

        const chatHubStore = useChatHubStore();
        await chatHubStore.inviteUserToChatroom(this.userProfile.email, chatroomId);

        this.$router.push({
          name: "Discussions",
          query: { chatroomId: chatroomId },
        });
      }
    },

    async followUser() {
      let profileClient = new ProfileClient();
      await profileClient.followUser(this.$route.params.userId);
      this.userProfile.isFollowed = true;
    },
    async unfollowUser() {
      let profileClient = new ProfileClient();
      await profileClient.unFollowUser(this.$route.params.userId);
      this.userProfile.isFollowed = false;
    },

    getAreaOfInterestDisplay(areaOfInterest: AreaOfInterestDto): string {
      if (areaOfInterest.parent != null) {
        return `${this.getAreaOfInterestDisplay(areaOfInterest.parent)} - ${areaOfInterest.title}`;
      } else {
        return areaOfInterest.title ?? "";
      }
    },
  },
  computed: {
    ...mapState(useProfileStore, ["profile", "areasOfInterest", "allSpecialties"]),
    ...mapGetters(["userId", "name", "hasDoctorRole"]),
    areasOfInterestFiltered(): AreaOfInterestDto[] {
      return this.areasOfInterest
        .filter((e) => !this.userProfile.areasOfInterest?.map((e) => e.id)?.includes(e.id))
        .sort((a, b) => this.getAreaOfInterestDisplay(a).localeCompare(this.getAreaOfInterestDisplay(b)));
    },
    onOwnProfilePage(): boolean {
      return this.$route.params.userId === undefined || this.$route.params.userId == this.userId;
    },
    role(): string {
      let role = this.userProfile.roles ? (this.userProfile.roles[0] as Roles) : "";
      switch (role) {
        case Roles.Admin:
          return "Admin";
        case Roles.Doctor:
          return "Doctor";
        case Roles.VoiceOf:
          return "Voice Of";
        case Roles.Sponsor:
          return "Sponsor";
        default:
          return "";
      }
    },
  },
  watch: {
    async $route(to, from) {
      // required if you go to your own profile page from another person's page
      await this.loadSelectedProfile();
    },
    async profile() {
      // required because profile takes a bit to asynchronously update and since we're using it in an async function,
      // vue doesn't know that we rely on it.
      await this.loadSelectedProfile();
    },
  },
});
