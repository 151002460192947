



















































import Vue from "vue";
import SetPassword from "@/components/userManagement/PasswordFields.vue";
import { Validation, validationMixin } from "vuelidate";
import { useErrorStore } from "@/store/errorStore";
import { mapActions } from "pinia";

import { required } from "vuelidate/lib/validators";
import { ValidationProperties, ValidationGroups } from "vue/types/vue";
import { ChangePasswordCommand, IChangePasswordCommand, ProblemDetails, ResetPasswordCommand, UserClient } from "@/api/DoceoApi";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "ChangePasswordDialog",
  components: { SetPassword, DoceoIcon },
  mixins: [validationMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  validations: {
    currentPassword: {
      required,
    },
  },
  data: () => ({
    currentPassword: "",
    errorMsg: undefined as string | undefined,
    isSubmitting: false,
    DOCEO_ICONS,
  }),
  computed: {
    /**
     * Password error checking
     */
    currentPasswordErrors(): string[] {
      const errors = [] as string[];
      if (!this.$v.currentPassword.$dirty) return errors;
      !this.$v.currentPassword.required && errors.push("Current password is required.");
      return errors;
    },
  },
  methods: {
    ...mapActions(useErrorStore, ["addMessage"]),
    /** Perform the request to change the user's password */
    async changePassword() {
      this.$v.$touch();
      var childForm: ValidationProperties<Vue> & ValidationGroups & Validation = (this.$refs.mychild as any).$v;
      childForm.$touch();
      this.errorMsg = undefined;

      if (this.$v.$invalid || childForm.$invalid) {
        return;
      }

      let changePassword: IChangePasswordCommand = {
        currentPassword: this.currentPassword,
        newPassword: childForm.password?.$model,
      };

      try {
        this.isSubmitting = true;
        let result = await new UserClient().changePassword(new ChangePasswordCommand(changePassword));

        if (result.succeeded) {
          this.addMessage("Password Reset");
          this.close();
        } else if (result.errors && result.errors.length > 0) {
          this.errorMsg = result.errors[0].description;
        } else {
          this.errorMsg = "Unknown error. Try again later"; // TODO: Proper error message
        }
      } catch (x) {
        if (x instanceof ProblemDetails) {
          this.errorMsg = x.detail;
        } else {
          this.errorMsg = "Unknown error. Try again later"; // TODO: Proper error message
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    /**
     * Emit an event to close the dialog
     */
    close() {
      this.$emit("close");
    },
  },
});
